import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { CaseTypeCount } from '../../types/analytics';
import DashboardSummary from '../dashboard/DashboardSummary';
import TicketTrendChart from '../dashboard/TicketTrendChart';
import TicketCategoryPieChart from '../dashboard/TicketCategoryPieChart';
import TotalTicketTrendChart from '../dashboard/TotalTicketTrendChart';

// Define the Case type if it's not available in a separate file
interface Case {
  Status: string;
  CreatedDate: string;
  Type: string;
}

interface AccountAnalyticsProps {
  accountCases: Case[];
  showSummaryOnly: boolean;
}

const AccountAnalytics: React.FC<AccountAnalyticsProps> = ({ accountCases, showSummaryOnly }) => {
  const chartData = useMemo(() => {
    const openAccountTickets = accountCases.filter(c => c.Status !== 'Closed');
    const closedAccountTickets = accountCases.filter(c => c.Status === 'Closed');

    // Calculate date 3 months ago (instead of 6)
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    // Set to start of that week
    threeMonthsAgo.setDate(threeMonthsAgo.getDate() - threeMonthsAgo.getDay() + 1);
    threeMonthsAgo.setHours(0, 0, 0, 0);

    // Generate array of all week start dates
    const weekStarts: string[] = [];
    const currentDate = new Date();
    let currentWeek = new Date(threeMonthsAgo);
    
    while (currentWeek <= currentDate) {
      weekStarts.push(currentWeek.toISOString().split('T')[0]);
      currentWeek.setDate(currentWeek.getDate() + 7);
    }

    // Filter tickets from last 3 months
    const recentTickets = accountCases.filter(ticket => 
      new Date(ticket.CreatedDate) >= threeMonthsAgo
    );

    // Initialize counts for all weeks
    const weeklyCounts: Record<string, Record<string, number>> = {};
    weekStarts.forEach(week => {
      weeklyCounts[week] = {};
    });

    // Group tickets by week and type
    recentTickets.forEach(ticket => {
      const createdDate = new Date(ticket.CreatedDate);
      const monday = new Date(createdDate);
      monday.setDate(createdDate.getDate() - createdDate.getDay() + 1);
      monday.setHours(0, 0, 0, 0);
      const weekKey = monday.toISOString().split('T')[0];
      const type = ticket.Type || 'Unspecified';
      
      if (!weeklyCounts[weekKey][type]) weeklyCounts[weekKey][type] = 0;
      weeklyCounts[weekKey][type]++;
    });

    const ticketTypes = Array.from(new Set(accountCases.map(c => c.Type || 'Unspecified')));

    // Ensure all ticket types have a value (even if 0) for all weeks
    const weeklyTicketCounts = weekStarts.map(week => {
      const counts: any = { week };
      ticketTypes.forEach(type => {
        counts[type] = weeklyCounts[week][type] || 0;
      });
      return counts;
    });

    // Group tickets by type for each category
    const openAccountTicketTypes: CaseTypeCount = {};
    const closedAccountTicketTypes: CaseTypeCount = {};

    accountCases.forEach(ticket => {
      const type = ticket.Type || 'Unspecified';
      if (ticket.Status !== 'Closed') {
        openAccountTicketTypes[type] = (openAccountTicketTypes[type] || 0) + 1;
      } else {
        closedAccountTicketTypes[type] = (closedAccountTicketTypes[type] || 0) + 1;
      }
    });

    // Update the weekly counts structure
    const weeklyTotalCounts = weekStarts.map(week => {
      const weekStart = new Date(week);
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 7);

      const weekTickets = recentTickets.filter(ticket => {
        const ticketDate = new Date(ticket.CreatedDate);
        return ticketDate >= weekStart && ticketDate < weekEnd;
      });

      return {
        week,
        open: weekTickets.filter(ticket => ticket.Status !== 'Closed').length,
        closed: weekTickets.filter(ticket => ticket.Status === 'Closed').length
      };
    });

    return {
      openAccountCount: openAccountTickets.length,
      closedAccountCount: closedAccountTickets.length,
      weeklyTicketCounts,
      ticketTypes,
      openAccountTicketTypes,
      closedAccountTicketTypes,
      weeklyTotalCounts,
    };
  }, [accountCases]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DashboardSummary
          activeCount={chartData.openAccountCount}
          resolvedCount={chartData.closedAccountCount}
          activeLabel="Active Account Tickets"
          resolvedLabel="Resolved Account Tickets"
        />
      </Grid>
      {!showSummaryOnly && (
        <>
          <Grid item xs={12} md={6}>
            <TicketTrendChart
              weeklyTicketCounts={chartData.weeklyTicketCounts}
              ticketTypes={chartData.ticketTypes}
              title="Account Weekly Tickets by Category (Last 3 Months)"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TotalTicketTrendChart
              weeklyTotalCounts={chartData.weeklyTotalCounts}
              title="Account Weekly Total Tickets (Last 3 Months)"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TicketCategoryPieChart
              data={chartData.openAccountTicketTypes}
              title="Active Account Tickets by Category"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TicketCategoryPieChart
              data={chartData.closedAccountTicketTypes}
              title="Resolved Account Tickets by Category"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AccountAnalytics;
