interface StatusStyle {
  bg: string;
  text: string;
  border: string;
}

// Case status styles
export const getCaseStatusStyle = (status?: string): StatusStyle => {
  switch (status?.toLowerCase()) {
    case 'new':
      return {
        bg: 'bg-cerulean-soft bg-opacity-20',
        text: 'text-white',
        border: 'border-cerulean-soft border-opacity-50'
      };
    case 'open':
      return {
        bg: 'bg-steel-blue bg-opacity-90',
        text: 'text-white',
        border: 'border-steel-blue border-opacity-50'
      };
    case 'on hold':
      return {
        bg: 'bg-cool-grey-02 bg-opacity-25',
        text: 'text-storm-dark',
        border: 'border-cool-grey-02 border-opacity-50'
      };
    case 'reopened':
      return {
        bg: 'bg-highlight-blue bg-opacity-90',
        text: 'text-white',
        border: 'border-highlight-blue border-opacity-50'
      };
    case 'closed':
      return {
        bg: 'bg-cool-grey-03 bg-opacity-20',
        text: 'text-cirrus',
        border: 'border-cool-grey-03 border-opacity-50'
      };
    default:
      return {
        bg: 'bg-nimbus bg-opacity-25',
        text: 'text-storm-dark',
        border: 'border-nimbus border-opacity-50'
      };
  }
};

// Jira status styles
export const getJiraStatusStyle = (status?: string): StatusStyle => {
  switch (status?.toLowerCase()) {
    case 'to do':
      return {
        bg: 'bg-cerulean-soft bg-opacity-90',
        text: 'text-white',
        border: 'border-cerulean-soft border-opacity-50'
      };
    case 'in progress':
      return {
        bg: 'bg-steel-blue bg-opacity-90',
        text: 'text-white',
        border: 'border-steel-blue border-opacity-50'
      };
    case 'pr created':
      return {
        bg: 'bg-slate bg-opacity-90',
        text: 'text-white',
        border: 'border-slate border-opacity-50'
      };
    case 'in staging':
      return {
        bg: 'bg-cool-grey-03 bg-opacity-90',
        text: 'text-white',
        border: 'border-cool-grey-03 border-opacity-50'
      };
    case 'in production':
      return {
        bg: 'bg-cerulean-light bg-opacity-90',
        text: 'text-white',
        border: 'border-cerulean-light border-opacity-50'
      };
    case 'done':
      return {
        bg: 'bg-cool-grey-04 bg-opacity-25',
        text: 'text-cirrus',
        border: 'border-cool-grey-04 border-opacity-50'
      };
    default:
      return {
        bg: 'bg-nimbus bg-opacity-25',
        text: 'text-storm-dark',
        border: 'border-nimbus border-opacity-50'
      };
  }
};

// Reusable status badge component
export const getStatusBadgeClasses = (style: StatusStyle): string => {
  return `px-3 py-1 rounded-full ${style.bg} ${style.text} ${style.border} border shadow-sm text-sm font-medium`;
};
