import React from 'react';

interface FormInputProps {
  id: string;
  name: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
}

export function FormInput({
  id,
  name,
  label,
  value,
  onChange,
  required = false,
  disabled = false,
  placeholder = '',
  type = 'text',
}: FormInputProps) {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-cool-grey-01 mb-1">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        className="w-full px-3 py-2 border border-cool-grey-02 rounded-md focus:ring-cerulean focus:border-cerulean transition duration-150 ease-in-out disabled:bg-cirrus disabled:text-storm"
      />
    </div>
  );
}
