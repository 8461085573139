'use client';

import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import CountUp from 'react-countup';
import { TrendingUp, CheckCircle } from 'lucide-react';
import { useDataContext } from '../../App';
import { chartColors } from '../../utils/chartColors';

interface DashboardSummaryProps {
  activeCount: number;
  resolvedCount: number;
  activeLabel: string;
  resolvedLabel: string;
}

const DashboardSummary: React.FC<DashboardSummaryProps> = ({
  activeCount,
  resolvedCount,
  activeLabel,
  resolvedLabel,
}) => {
  const { deviceType } = useDataContext();

  return (
    <Paper 
      elevation={1} 
      className="p-6 rounded-lg shadow-md"
    >
      <Box className={`
        flex 
        ${deviceType === 'mobile' ? 'flex-col space-y-6' : 'flex-row justify-around'} 
        items-center
      `}>
        <SummaryItem
          count={activeCount}
          label={`${activeLabel} (Last 3 Months)`}
          icon={<TrendingUp size={28} />}
          color={chartColors.primary[0]} // Using the first primary color (Blue)
        />
        <SummaryItem
          count={resolvedCount}
          label={`${resolvedLabel} (Last 3 Months)`}
          icon={<CheckCircle size={28} />}
          color={chartColors.secondary[2]} // Using the green from secondary colors
        />
      </Box>
    </Paper>
  );
};

interface SummaryItemProps {
  count: number;
  label: string;
  icon: React.ReactNode;
  color: string;
}

const SummaryItem: React.FC<SummaryItemProps> = ({ count, label, icon, color }) => (
  <Box className="flex items-center space-x-4 p-4">
    <Box style={{ color }}>{icon}</Box>
    <Box>
      <Typography variant="h4" style={{ fontWeight: 'bold', color }}>
        <CountUp end={count} duration={2} />
      </Typography>
      <Typography variant="body1" className="text-cool-grey-01">{label}</Typography>
    </Box>
  </Box>
);

export default DashboardSummary;
