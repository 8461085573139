import React from 'react';
import { Paper, Typography, Box, Tooltip, IconButton } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { CaseTypeCount } from '../../types/analytics';
import { useDataContext } from '../../App';
import { HelpCircle } from 'lucide-react';
import { getChartColorByIndex } from '../../utils/chartColors';

interface TicketCategoryPieChartProps {
  data: CaseTypeCount;
  title: string;
}

const TicketCategoryPieChart: React.FC<TicketCategoryPieChartProps> = ({ data, title }) => {
  const { deviceType } = useDataContext();

  const pieData = Object.entries(data).map(([label, value], index) => ({ 
    id: index,
    value,
    label: `${label} (${value})`,  // Show counts on the pie chart
    color: getChartColorByIndex(index),
  }));

  const chartHeight = deviceType === 'mobile' ? 300 : deviceType === 'tablet' ? 325 : 350;
  const legendWidth = deviceType === 'mobile' ? '100%' : deviceType === 'tablet' ? '30%' : 160;
  const chartWidth = deviceType === 'mobile' ? '100%' : deviceType === 'tablet' ? '70%' : `calc(100% - ${legendWidth}px)`;

  const tooltipText = `
    This pie chart shows the distribution of tickets by category:
    • Each slice represents a different ticket category
    • The size of each slice shows the proportion of tickets in that category
    • Hover over slices to see exact numbers
    • Use this to identify which types of tickets are most common
  `;

  return (
    <Paper 
      elevation={3} 
      className="p-6 rounded-lg shadow-md"
    >
      <Box className="flex items-center gap-2 mb-2">
        <Typography variant="h6" className="text-storm font-semibold">
          {title}
        </Typography>
        <Tooltip 
          title={tooltipText}
          arrow
          placement="top"
          classes={{
            tooltip: 'max-w-md whitespace-pre-line'
          }}
        >
          <IconButton size="small" className="text-cool-grey-01">
            <HelpCircle size={16} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" flexDirection={deviceType === 'mobile' ? 'column' : 'row'}>
        <Box 
          width={legendWidth} 
          mb={deviceType === 'mobile' ? 2 : 0} 
          pr={2}
          order={deviceType === 'mobile' ? 2 : 1}
        >
          {pieData.map((item) => (
            <Box key={item.id} display="flex" alignItems="center" mb={1}>
              <Box
                width={10}
                height={10}
                bgcolor={item.color}
                mr={1}
                flexShrink={0}
              />
              <Typography variant="body2" noWrap style={{ overflow: 'visible' }}>
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box 
          width={chartWidth}
          order={deviceType === 'mobile' ? 1 : 2}
        >
          <PieChart
            series={[
              {
                data: pieData,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                // Removed the label property as it is not supported
              },
            ]}
            height={chartHeight}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default TicketCategoryPieChart;
