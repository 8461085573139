import React from 'react';
import { AccountRecord } from '../../types/AccountRecord';
import ErrorMessage from '../ui-elements/ErrorMessage';
import RecordDetails, { FieldConfig } from '../ui-elements/RecordDetails';

interface AccountDetailsProps {
  accountRecord: AccountRecord | null;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ accountRecord }) => {
  if (!accountRecord) {
    return <ErrorMessage message="No account details available. Please contact support@ekwis.com for assistance." />;
  }

  const fields: FieldConfig[] = [
    { label: 'Account Name', value: accountRecord.Name },
    { label: 'Phone', value: accountRecord.Phone },
    { label: 'Website', value: accountRecord.Website },
    { label: 'Industry', value: accountRecord.Industry },
    { 
      label: 'Billing Address', 
      value: [
        accountRecord.BillingStreet,
        accountRecord.BillingCity,
        accountRecord.BillingState,
        accountRecord.BillingPostalCode,
        accountRecord.BillingCountry
      ].filter(Boolean).join('\n'),
      fullWidth: true 
    }
  ];

  return (
    <div className="overflow-x-auto">
      <RecordDetails fields={fields} />
    </div>
  );
};

export default AccountDetails;
