'use client';

import { FaTimes } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
import Button from './Button';

interface FormActionsProps {
  onClear: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isSubmitting: boolean;
  submitText: string;
}

export const FormActions: React.FC<FormActionsProps> = ({
  onClear,
  onSubmit,
  isSubmitting,
  submitText
}) => {
  return (
    <div className="flex justify-end space-x-4 mt-6">
      <Button
        onClick={onClear}
        variant="secondary"
        disabled={isSubmitting}
        type="button"
      >
        <FaTimes className="mr-2" />
        Clear
      </Button>
      <Button
        variant="primary"
        disabled={isSubmitting}
        type="submit"
      >
        {submitText}
        <IoMdSend className="ml-2" />
      </Button>
    </div>
  );
};
