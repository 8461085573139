import React from 'react';
import { Routes, Route, Navigate, useParams, useLocation } from 'react-router-dom';
import { CasesTable } from '../components/case/CasesTable';
import LoadingSpinner from '../components/ui-elements/LoadingSpinner';
import PageLayout from '../components/layout/PageLayout';
import { useDataContext } from '../App';
import { 
  FaTicket,
  FaUserCheck,
  FaUserXmark,
  FaBuildingCircleCheck,
  FaBuildingCircleXmark,
  FaCodeMerge,
  FaCodePullRequest,
  FaDisplay,  // New icon for personal staging
  FaServer    // New icon for account staging
} from 'react-icons/fa6';
import NoContactFound from '../components/ui-elements/NoContactFound';
import { SidebarOption } from '../components/navigation/Sidebar';

const sidebarOptions: SidebarOption[] = [
  { 
    label: 'My In Staging Tickets', 
    path: '/tickets/contact/jira-status/In%20Staging', 
    icon: FaDisplay  // Changed to display/monitor icon for personal staging
  },
  { 
    label: 'Account In Staging Tickets', 
    path: '/tickets/account/jira-status/In%20Staging', 
    icon: FaServer  // Changed to server icon for account staging
  },
  { 
    label: 'My PR Created Tickets', 
    path: '/tickets/contact/jira-status/PR%20Created', 
    icon: FaCodeMerge  // Kept the same for PR
  },
  { 
    label: 'Account PR Created Tickets', 
    path: '/tickets/account/jira-status/PR%20Created', 
    icon: FaCodePullRequest  // Kept the same for PR
  },
  { 
    label: 'My Open Tickets', 
    path: '/tickets/contact/open', 
    icon: FaUserCheck
  },
  { 
    label: 'My Closed Tickets', 
    path: '/tickets/contact/closed', 
    icon: FaUserXmark
  },
  { 
    label: 'Open Account Tickets', 
    path: '/tickets/account/open', 
    icon: FaBuildingCircleCheck
  },
  { 
    label: 'Closed Account Tickets', 
    path: '/tickets/account/closed', 
    icon: FaBuildingCircleXmark
  },
];

const Tickets: React.FC = () => {
  const { contactRecord, accountRecord, loading, noContactFound } = useDataContext();
  const location = useLocation();

  // Function to get the current selected option's label
  const getCurrentPageTitle = () => {
    const currentOption = sidebarOptions.find(option => {
      // If the path starts with #, compare with location.hash
      if (option.path.startsWith('#')) {
        return location.hash === option.path;
      }
      // For paths with parameters, check if the current path starts with the option path
      if (option.path.includes('/tickets/')) {
        return location.pathname.startsWith(option.path);
      }
      // Otherwise compare with full pathname
      return location.pathname === option.path;
    });

    return currentOption?.label || 'Tickets';
  };

  if (loading) {
    return <LoadingSpinner message="Loading..." />;
  }

  if (noContactFound) {
    return <NoContactFound pageTitle={getCurrentPageTitle()} />;
  }

  return (
    <PageLayout
      showSidebar={true}
      sidebarOptions={sidebarOptions}
      sidebarTitle="Tickets"
      pageTitle={getCurrentPageTitle()}
      pageIcon={FaTicket}
      withContentWrapper={false} // Disable the white wrapper
    >
      <Routes>
        <Route index element={<Navigate to="/tickets/contact/open" replace />} />
        <Route 
          path=":idType/:caseType/*" 
          element={
            contactRecord && accountRecord ? (
              <CaseTableWrapper 
                contactId={contactRecord.Id} 
                accountId={accountRecord.Id} 
              />
            ) : (
              <LoadingSpinner message="Loading user data..." />
            )
          } 
        />
      </Routes>
    </PageLayout>
  );
};

interface CaseTableWrapperProps {
  contactId?: string;
  accountId?: string;
}

const CaseTableWrapper: React.FC<CaseTableWrapperProps> = ({ contactId, accountId }) => {
  const { idType, caseType, '*': restOfPath } = useParams<{ idType: string; caseType: string; '*': string }>();

  const id = idType === 'contact' ? contactId : accountId;

  if (!id) {
    return <LoadingSpinner message="Loading user data..." />;
  }

  const jiraStatus = caseType === 'jira-status' ? restOfPath : undefined;

  return (
    <CasesTable
      caseType={caseType as 'all' | 'open' | 'closed' | 'to-test' | 'jira-status'}
      idType={idType as 'contact' | 'account'}
      id={id}
      jiraStatus={jiraStatus}
    />
  );
};

export default Tickets;
