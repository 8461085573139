import { useState } from 'react';

export function useColumnWidths() {
  return useState<number[]>([
    120, // 'Actions' initial width
    80,  // 'Case Number' initial width
    300, // 'Subject' initial width
    80,  // 'Status' initial width
    100, // 'JIRA Ticket' initial width
    100, // 'JIRA Status' initial width
    100, // 'Origin' initial width
    150, // 'Created Date' initial width
  ]);
}
