import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDataContext } from '../App';
import AccountDetails from '../components/profile/AccountDetails';
import ContactDetails from '../components/profile/ContactDetails';
import Auth0Details from '../components/profile/Auth0Details';
import LoadingSpinner from '../components/ui-elements/LoadingSpinner';
import ErrorMessage from '../components/ui-elements/ErrorMessage';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import PageLayout from '../components/layout/PageLayout';
import { SidebarOption } from '../components/navigation/Sidebar';
import { FaUser, FaBuilding, FaIdCard } from 'react-icons/fa';
import NoContactFound from '../components/ui-elements/NoContactFound';

const sidebarOptions: SidebarOption[] = [
  { label: 'Account Details', path: '/profile/account', icon: FaBuilding },
  { label: 'Contact Details', path: '/profile/contact', icon: FaIdCard },
  { label: 'Auth0 Details', path: '/profile/auth0', icon: FaUser },
];

const ProfilePage: React.FC = () => {
  const { user, isLoading } = useAuth0();
  const { contactRecord, accountRecord, loading, error, noContactFound } = useDataContext();
  const location = useLocation();

  if (noContactFound) {
    return <NoContactFound pageTitle="Profile" />;
  }

  if (isLoading || loading) {
    return <LoadingSpinner message="Loading profile data..." />;
  }

  const getTitle = () => {
    const path = location.pathname;
    if (path.includes('/profile/account')) return 'Account Details';
    if (path.includes('/profile/contact')) return 'Contact Details';
    if (path.includes('/profile/auth0')) return 'Auth0 Details';
    return 'Your Details';
  };

  return (
    <PageLayout 
      showSidebar={true}
      sidebarOptions={sidebarOptions} 
      sidebarTitle="My Details"
      pageTitle={getTitle()}
      pageIcon={FaUser}
    >
      {loading ? (
        <LoadingSpinner message="Loading profile data..." />
      ) : error ? (
        <ErrorMessage message={error} />
      ) : (
        <Routes>
          <Route index element={<Navigate to="account" replace />} />
          <Route path="account" element={<AccountDetails accountRecord={accountRecord} />} />
          <Route path="contact" element={<ContactDetails contactRecord={contactRecord} />} />
          <Route
            path="auth0"
            element={
              user ? (
                <Auth0Details user={user} />
              ) : (
                <ErrorMessage message="User data not available" />
              )
            }
          />
        </Routes>
      )}
    </PageLayout>
  );
};

export default ProfilePage;
