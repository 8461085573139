import React from 'react';
import { IconType } from 'react-icons';
import LoadingSpinner from './LoadingSpinner';

interface ButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary';
  icon?: IconType;
  disabled?: boolean;
  loading?: boolean;
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  variant = 'primary',
  icon: Icon,
  disabled = false,
  loading = false,
  size = 'medium',
  type = 'button',
}) => {
  const baseClasses = 'font-semibold rounded-md transition-colors duration-200 flex items-center justify-center';
  
  const sizeClasses = {
    small: 'text-xs px-2 py-1',
    medium: 'text-sm px-4 py-2',
    large: 'text-base px-6 py-3',
  };

  const variantClasses = {
    primary: 'bg-cerulean text-white hover:bg-cerulean-dark',
    secondary: 'bg-cool-grey-02 text-storm hover:bg-cool-grey-01',
    tertiary: 'bg-transparent text-storm hover:bg-cool-grey-02',
  };

  const classes = `${baseClasses} ${sizeClasses[size]} ${variantClasses[variant]}`;

  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={classes}
      type={type}
    >
      {loading ? (
        <LoadingSpinner size="small" variant="inline" />
      ) : (
        <>
          {Icon && <Icon className={`mr-2 ${size === 'small' ? 'w-3 h-3' : 'w-4 h-4'}`} />}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;
