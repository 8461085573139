import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Case } from '../../types/CaseRecord';
import CaseActionsMenu from './CaseActionsMenu';
import SortArrow from '../ui-elements/SortArrow';
import { formatDate } from '../../utils/caseUtils';
import { getCaseStatusStyle, getJiraStatusStyle, getStatusBadgeClasses } from '../../utils/statusStyles';

interface CasesDesktopViewProps {
  cases: Case[];
  columnWidths: number[];
  sortConfig: { key: keyof Case; direction: 'ascending' | 'descending' };
  onRequestSort: (key: keyof Case) => void;
  onCaseDetailsClick: (caseItem: Case) => void;
  onCaseClosed: (caseId: string, newStatus: string) => void;
  onCaseOpened: (caseId: string, newStatus: string) => void;
  onAddComment: (caseItem: Case) => void;
  closingCaseId: string | null;
  openingCaseId: string | null;
  onActionStart: (caseId: string, action: 'open' | 'close') => void;
  onActionEnd: () => void;
  onColumnResize: (index: number, e: React.MouseEvent) => void;
}

const CasesDesktopView: React.FC<CasesDesktopViewProps> = ({
  cases,
  columnWidths,
  sortConfig,
  onRequestSort,
  onCaseDetailsClick,
  onCaseClosed,
  onCaseOpened,
  onAddComment,
  closingCaseId,
  openingCaseId,
  onActionStart,
  onActionEnd,
  onColumnResize,
}) => {
  return (
    <div className="overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {['Actions', 'Case Number', 'Subject', 'Status', 'JIRA Ticket', 'JIRA Status', 'Origin', 'Created Date'].map((header, index) => (
              <th
                key={header}
                className={`
                  bg-cirrus
                  ${index === 0 ? 'rounded-tl-lg' : ''} 
                  ${index === ['Actions', 'Case Number', 'Subject', 'Status', 'JIRA Ticket', 'JIRA Status', 'Origin', 'Created Date'].length - 1 ? 'rounded-tr-lg' : ''}
                  ${index === 0 ? 'text-center' : 'text-left'} 
                  px-2 py-3 text-xs font-medium text-cool-grey-01 uppercase tracking-wider
                  relative
                `}
                style={{ width: `${columnWidths[index]}px` }}
                onClick={() => onRequestSort(header as keyof Case)}
              >
                <div className={`flex items-center ${index === 0 ? 'justify-center' : 'justify-between'}`}>
                  <span className="mr-2">{header}</span>
                  {index !== 0 && <SortArrow sortKey={header as keyof Case} currentSortKey={sortConfig.key} direction={sortConfig.direction} />}
                </div>
                <div
                  className="absolute right-0 top-0 h-full w-2 cursor-col-resize select-none opacity-0 group-hover:opacity-100"
                  onMouseDown={(e) => onColumnResize(index, e)}
                ></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {cases.map((caseItem, index) => (
            <tr
              key={`${caseItem.Id || caseItem.CaseNumber || index}`}
              className={`
                ${index % 2 === 0 ? 'bg-white' : 'bg-cirrus'}
                hover:bg-cool-grey-02 transition-colors duration-200
              `}
            >
              <td className="px-0 py-2 whitespace-nowrap text-sm text-cool-grey-01 border-b border-cool-grey-02 text-center">
                <CaseActionsMenu
                  caseItem={caseItem}
                  onCaseClosed={onCaseClosed}
                  onCaseOpened={onCaseOpened}
                  onSendComment={onAddComment}
                  closingCaseId={closingCaseId}
                  openingCaseId={openingCaseId}
                  onActionStart={onActionStart}
                  onActionEnd={onActionEnd}
                />
              </td>
              <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-storm border-b border-cool-grey-02">
                <span 
                  className="text-cerulean hover:underline cursor-pointer"
                  onClick={() => onCaseDetailsClick(caseItem)}
                >
                  {caseItem.CaseNumber}
                </span>
              </td>
              <td className="px-2 py-2 text-sm text-cool-grey-01 border-b border-cool-grey-02">
                <span 
                  className="text-cerulean hover:underline cursor-pointer"
                  onClick={() => onCaseDetailsClick(caseItem)}
                >
                  {caseItem.Subject}
                </span>
              </td>
              <td className="px-2 py-2 whitespace-nowrap border-b border-cool-grey-02">
                <span className={getStatusBadgeClasses(getCaseStatusStyle(caseItem.Status))}>
                  {caseItem.Status || 'N/A'}
                </span>
              </td>
              <td className="px-2 py-2 whitespace-nowrap text-sm text-cool-grey-01 border-b border-cool-grey-02">
                {caseItem.JIRA_Ticket_Key__c || 'N/A'}
              </td>
              <td className="px-2 py-2 whitespace-nowrap border-b border-cool-grey-02">
                <span className={getStatusBadgeClasses(getJiraStatusStyle(caseItem.JIRA_Status__c))}>
                  {caseItem.JIRA_Status__c || 'N/A'}
                </span>
              </td>
              <td className="px-2 py-2 whitespace-nowrap text-sm text-cool-grey-01 border-b border-cool-grey-02">{caseItem.Origin || 'N/A'}</td>
              <td className="px-2 py-2 whitespace-nowrap text-sm text-cool-grey-01 border-b border-cool-grey-02">{caseItem.CreatedDate ? formatDate(caseItem.CreatedDate) : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CasesDesktopView;
