import React, { ReactNode } from 'react';
import { IconType } from 'react-icons';

interface TitleSectionProps {
  title: string;
  subtitle?: string;
  icon?: IconType;
  action?: ReactNode;
}

const TitleSection: React.FC<TitleSectionProps> = ({ title, subtitle, icon: Icon, action }) => {
  return (
    <div className="bg-cirrus py-4 px-6 shadow-md rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex items-center min-w-0 flex-1">
          {Icon && <Icon className="text-storm mr-3 text-2xl flex-shrink-0" />}
          <div className="min-w-0">
            <h1 className="text-2xl font-bold text-storm truncate">{title}</h1>
            {subtitle && (
              <p className="text-lg text-cool-grey-01 mt-1 truncate">
                {subtitle}
              </p>
            )}
          </div>
        </div>
        {action && (
          <div className="ml-4 flex-shrink-0">
            {action}
          </div>
        )}
      </div>
    </div>
  );
};

export default TitleSection;
