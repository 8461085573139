import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDataContext } from '../../App';
import { addEmailToCase } from '../../api/cases';
import LoadingSpinner from '../ui-elements/LoadingSpinner';
import Toast from '../ui-elements/Toast';
import { FormInput } from '../ui-elements/FormInput';
import { RichTextEditorWithLabel } from '../ui-elements/RichTextEditorWithLabel';
import { FormActions } from '../ui-elements/FormActions';
import { extractPlainText } from '../../utils/textUtils';
import { PopupModal } from '../layout/PopupModal';
import { Auth0ContextInterface } from '@auth0/auth0-react';

interface AddCaseCommentProps {
  caseId: string;
  caseNumber: string;
  onCommentSent: (success: boolean) => void;
  onClose: () => void;
}

const AddCaseComment: React.FC<AddCaseCommentProps> = ({
  caseId,
  caseNumber,
  onCommentSent,
  onClose,
}) => {
  const { getAccessTokenSilently }: Auth0ContextInterface = useAuth0();
  const { contactRecord } = useDataContext();
  const [subject, setSubject] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [body, setBody] = useState('');
  const [editorKey, setEditorKey] = useState('0');

  const handleClear = () => {
    setSubject('');
    setBody('');
    setEditorKey(prevKey => (parseInt(prevKey) + 1).toString());
    setStatus('idle');
  };

  useEffect(() => {
    if (status === 'success') {
      // First notify parent of success
      onCommentSent(true);
      // Then close after a brief delay to allow toast to show
      const timer = setTimeout(() => {
        onClose();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [status, onCommentSent, onClose]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const textContent = extractPlainText(body);

    if (!subject.trim() || !textContent) {
      setStatus('error');
      setErrorMessage('Subject and comment are required.');
      return;
    }
    setLoading(true);
    setStatus('idle');

    try {
      const token = await getAccessTokenSilently();
      await addEmailToCase(token, caseId, {
        FromAddress: contactRecord.Email,
        FromName: `${contactRecord.FirstName} ${contactRecord.LastName}`,
        Subject: subject,
        TextBody: textContent,
        HTMLBody: body,
      });

      setStatus('success');
      setLoading(false);
    } catch (error) {
      console.error('Error sending comment:', error);
      setStatus('error');
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderStatusMessage = () => {
    if (status === 'success') {
      return (
        <Toast
          title="Success"
          message="Comment added successfully"
          type="success"
          duration={3000}
          onClose={() => setStatus('idle')}
        />
      );
    } else if (status === 'error') {
      return (
        <Toast
          title="Error"
          message={errorMessage}
          type="error"
          duration={5000}
          onClose={() => setStatus('idle')}
        />
      );
    }
    return null;
  };

  return (
    <PopupModal
      title="Add Comment to Case"
      subtitle={`Case Number: ${caseNumber}`}
      onClose={onClose}
      isLoading={loading}
    >
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
            <LoadingSpinner size="large" message="Sending comment..." />
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          {renderStatusMessage()}
          <FormInput
            id="subject"
            name="subject"
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            disabled={loading}
            placeholder="Enter comment subject"
          />
          <RichTextEditorWithLabel
            label="Comment"
            content={body}
            onUpdate={setBody}
            editorKey={editorKey}
            disabled={loading}
            required
          />
          <FormActions
            onClear={handleClear}
            onSubmit={handleSubmit}
            isSubmitting={loading}
            submitText="Send Comment"
          />
        </form>
        {status === 'error' && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
      </div>
    </PopupModal>
  );
};

export default AddCaseComment;
