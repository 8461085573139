// Utility function to get environment variables in the frontend
export const getEnvVariables = () => {
    const envVars = {
      AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
      AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
      API_URL: process.env.REACT_APP_API_URL,
    };
  
    // Optionally log missing variables (only in development mode)
    if (process.env.NODE_ENV === 'development') {
      Object.entries(envVars).forEach(([key, value]) => {
        if (!value) {
          console.warn(`Missing environment variable: ${key}`);
        }
      });
    }
  
    return envVars;
  };