import React from 'react';
import { FaPaperPlane, FaLock, FaLockOpen } from 'react-icons/fa';
import { Case } from '../../types/CaseRecord';
import { useCaseManagement } from '../../hooks/useCaseManagement';
import { useDataContext } from '../../App';

interface CaseActionsMenuProps {
  caseItem: Case;
  onCaseClosed: (caseId: string, newStatus: string) => void;
  onCaseOpened: (caseId: string, newStatus: string) => void;
  onSendComment: (caseItem: Case) => void;
  closingCaseId: string | null;
  openingCaseId: string | null;
  onActionStart: (caseId: string, action: 'open' | 'close') => void;
  onActionEnd: () => void;
}

const CaseActionsMenu: React.FC<CaseActionsMenuProps> = ({
  caseItem,
  onCaseClosed,
  onCaseOpened,
  onSendComment,
  closingCaseId,
  openingCaseId,
  onActionStart,
  onActionEnd,
}) => {
  const { closeCase, openCase } = useCaseManagement();
  const { deviceType } = useDataContext();

  const handleSendComment = () => {
    onSendComment(caseItem);
  };

  const handleCloseCase = async () => {
    onActionStart(caseItem.Id || '', 'close');
    try {
      const result = await closeCase(caseItem.Id || '');
      if (result && result.Status) {
        onCaseClosed(caseItem.Id || '', result.Status);
      } else {
        console.error('Closed case status is undefined');
        onCaseClosed(caseItem.Id || '', 'Closed');
      }
    } catch (err) {
      console.error('Failed to close case:', err);
      onCaseClosed(caseItem.Id || '', 'Closed');
    } finally {
      onActionEnd();
    }
  };

  const handleOpenCase = async () => {
    onActionStart(caseItem.Id || '', 'open');
    try {
      const result = await openCase(caseItem.Id || '');
      if (result && result.Status) {
        onCaseOpened(caseItem.Id || '', result.Status);
      } else {
        console.error('Reopened case status is undefined');
        onCaseOpened(caseItem.Id || '', 'Reopened');
      }
    } catch (err) {
      console.error('Failed to open case:', err);
      onCaseOpened(caseItem.Id || '', 'Reopened');
    } finally {
      onActionEnd();
    }
  };

  const buttonClasses = `
    px-2 py-1 text-xs font-medium text-gray-900 bg-white border border-gray-200
    hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700
    flex items-center justify-center
  `;

  const loadingClasses = 'bg-gray-300 text-gray-500 cursor-not-allowed';

  const isStackedLayout = deviceType === 'mobile';

  return (
    <div 
      className={`inline-flex rounded-md shadow-sm ${isStackedLayout ? 'flex-col' : 'flex-row'}`} 
      role="group"
    >
      {caseItem.Status === 'Closed' ? (
        <button
          type="button"
          className={`
            ${buttonClasses} 
            ${isStackedLayout ? 'rounded-t-lg' : 'rounded-l-lg'} 
            ${openingCaseId === caseItem.Id ? loadingClasses : ''}
          `}
          onClick={handleOpenCase}
          disabled={openingCaseId === caseItem.Id}
          title="Reopen Case"
        >
          <FaLockOpen className="w-4 h-4" />
        </button>
      ) : (
        <button
          type="button"
          className={`
            ${buttonClasses} 
            ${isStackedLayout ? 'rounded-t-lg' : 'rounded-l-lg'} 
            ${closingCaseId === caseItem.Id ? loadingClasses : ''}
          `}
          onClick={handleCloseCase}
          disabled={closingCaseId === caseItem.Id}
          title="Close Case"
        >
          <FaLock className="w-4 h-4" />
        </button>
      )}
      <button
        type="button"
        className={`
          ${buttonClasses} 
          ${isStackedLayout ? 'rounded-b-lg' : 'rounded-r-lg'}
          ${isStackedLayout ? 'border-t-0' : 'border-l-0'}
        `}
        onClick={handleSendComment}
        title="Send Comment"
      >
        <FaPaperPlane className="w-4 h-4" />
      </button>
    </div>
  );
};

export default CaseActionsMenu;
