import React from 'react';
import { ContactRecord } from '../../types/ContactRecord';
import ErrorMessage from '../ui-elements/ErrorMessage';
import RecordDetails, { FieldConfig } from '../ui-elements/RecordDetails';

interface ContactDetailsProps {
  contactRecord: ContactRecord | null;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ contactRecord }) => {
  if (!contactRecord) {
    return <ErrorMessage message="No Salesforce details found. Please contact support@ekwis.com to set you up on this portal." />;
  }

  const fields: FieldConfig[] = [
    { label: 'First Name', value: contactRecord.FirstName },
    { label: 'Last Name', value: contactRecord.LastName },
    { label: 'Email', value: contactRecord.Email },
    { label: 'Phone', value: contactRecord.Phone },
    { label: 'Mobile', value: contactRecord.MobilePhone },
    { label: 'Title', value: contactRecord.Title },
    { label: 'Department', value: contactRecord.Department }
  ];

  return (
    <div className="overflow-x-auto">
      <RecordDetails fields={fields} />
    </div>
  );
};

export default ContactDetails;
