import React from 'react';
import { FaTimes } from 'react-icons/fa';

interface PopupModalProps {
  title: string;
  subtitle?: string;
  onClose: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
}

export const PopupModal: React.FC<PopupModalProps> = ({
  title,
  subtitle,
  onClose,
  children,
  isLoading = false,
}) => {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white w-full max-w-3xl rounded-lg shadow-lg overflow-hidden">
        <div className="bg-cerulean text-white px-6 py-4 flex justify-between items-center">
          <div>
            <h2 className="text-xl font-bold">{title}</h2>
            {subtitle && <p className="text-sm mt-1">{subtitle}</p>}
          </div>
          <button
            onClick={onClose}
            className="text-white hover:text-cool-grey-02 transition-colors duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading}
          >
            <FaTimes />
          </button>
        </div>
        <div className="p-6 overflow-y-auto max-h-[calc(100vh-200px)]">
          {children}
        </div>
      </div>
    </div>
  );
};
