import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createCase, closeCase, openCase, createCaseWithEmail, addEmailToCase } from '../api/cases';
import { Case } from '../types/CaseRecord';

// Replace the CaseData interface with a new interface that extends Case
interface CreateCaseData extends Partial<Case> {
  ContactId: string;
  AccountId: string;
  Subject: string;
  Description?: string;
  FromAddress: string;
  FromName: string;
}

export const useCaseManagement = () => {
  const [loading, setLoading] = useState({
    create: false,
    close: false,
    open: false,
    createWithEmail: false,
    addEmail: false,
  });
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const handleCase = useCallback(async (action: 'create' | 'close' | 'open' | 'createWithEmail' | 'addEmail', ...args: any[]) => {
    setLoading((prev) => ({ ...prev, [action]: true }));
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      let result;

      switch (action) {
        case 'create':
          result = await createCase(token, args[0] as CreateCaseData);
          break;
        case 'close':
          result = await closeCase(token, args[0] as string);
          break;
        case 'open':
          result = await openCase(token, args[0] as string);
          break;
        case 'createWithEmail':
          result = await createCaseWithEmail(token, args[0] as CreateCaseData);
          break;
        case 'addEmail':
          result = await addEmailToCase(token, args[0] as string, args[1]);
          break;
      }

      setLoading((prev) => ({ ...prev, [action]: false }));
      return result;
    } catch (err) {
      setLoading((prev) => ({ ...prev, [action]: false }));
      setError(`An error occurred while ${action === 'createWithEmail' ? 'creating the case with email' : `${action}ing the case`}.`);
      throw err;
    }
  }, [getAccessTokenSilently]);

  return {
    createCase: useCallback((caseData: CreateCaseData) => handleCase('create', caseData), [handleCase]),
    closeCase: useCallback((caseId: string) => handleCase('close', caseId), [handleCase]),
    openCase: useCallback((caseId: string) => handleCase('open', caseId), [handleCase]),
    createCaseWithEmail: useCallback((caseData: CreateCaseData) => handleCase('createWithEmail', caseData), [handleCase]),
    addEmailToCase: useCallback((caseId: string, emailData: any) => handleCase('addEmail', caseId, emailData), [handleCase]),
    loading,
    error
  };
};
