import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Case } from '../../types/CaseRecord';
import CaseActionsMenu from './CaseActionsMenu';
import { formatDate } from '../../utils/caseUtils';
import { getCaseStatusStyle, getJiraStatusStyle, getStatusBadgeClasses } from '../../utils/statusStyles';

interface CasesMobileViewProps {
  cases: Case[];
  onCaseDetailsClick: (caseItem: Case) => void;
  onCaseClosed: (caseId: string, newStatus: string) => void;
  onCaseOpened: (caseId: string, newStatus: string) => void;
  onAddComment: (caseItem: Case) => void;
  closingCaseId: string | null;
  openingCaseId: string | null;
  onActionStart: (caseId: string, action: 'open' | 'close') => void;
  onActionEnd: () => void;
}

const CasesMobileView: React.FC<CasesMobileViewProps> = ({
  cases,
  onCaseDetailsClick,
  onCaseClosed,
  onCaseOpened,
  onAddComment,
  closingCaseId,
  openingCaseId,
  onActionStart,
  onActionEnd,
}) => {
  return (
    <div className="grid gap-6 p-6">
      {cases.map((caseItem, index) => (
        <Card key={`${caseItem.Id || caseItem.CaseNumber || index}`} className="bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
          <CardContent>
            <div className="flex items-start mb-4">
              <div className="mr-4">
                <CaseActionsMenu
                  caseItem={caseItem}
                  onCaseClosed={onCaseClosed}
                  onCaseOpened={onCaseOpened}
                  onSendComment={onAddComment}
                  closingCaseId={closingCaseId}
                  openingCaseId={openingCaseId}
                  onActionStart={onActionStart}
                  onActionEnd={onActionEnd}
                />
              </div>
              <div className="flex-grow grid grid-cols-2 gap-2">
                <Typography variant="h6" component="div" className="text-storm font-bold col-span-2">
                  <span 
                    className="text-cerulean hover:underline cursor-pointer"
                    onClick={() => onCaseDetailsClick(caseItem)}
                  >
                    {caseItem.Subject}
                  </span>
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01 font-medium">
                  Case Number:
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01">
                  <span 
                    className="text-cerulean hover:underline cursor-pointer"
                    onClick={() => onCaseDetailsClick(caseItem)}
                  >
                    {caseItem.CaseNumber}
                  </span>
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01 font-medium">
                  Status:
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01">
                  <span className={getStatusBadgeClasses(getCaseStatusStyle(caseItem.Status))}>
                    {caseItem.Status || 'N/A'}
                  </span>
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01 font-medium">
                  Created:
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01">
                  {caseItem.CreatedDate ? formatDate(caseItem.CreatedDate) : 'N/A'}
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01 font-medium">
                  JIRA Ticket:
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01">
                  {caseItem.JIRA_Ticket_Key__c || 'N/A'}
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01 font-medium">
                  JIRA Status:
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01">
                  <span className={getStatusBadgeClasses(getJiraStatusStyle(caseItem.JIRA_Status__c))}>
                    {caseItem.JIRA_Status__c || 'N/A'}
                  </span>
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01 font-medium">
                  Origin:
                </Typography>
                <Typography variant="body2" className="text-cool-grey-01">
                  {caseItem.Origin || 'N/A'}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default CasesMobileView;
