export function extractPlainText(html: string): string {
  let text = html.replace(/<br\s*\/?>/gi, '\n')
                 .replace(/<\/(p|div|h1|h2|h3|h4|h5|h6)>/gi, '\n\n');
  text = text.replace(/<[^>]+>/g, '');
  text = text.replace(/&nbsp;/g, ' ')
             .replace(/&amp;/g, '&')
             .replace(/&lt;/g, '<')
             .replace(/&gt;/g, '>')
             .replace(/&quot;/g, '"')
             .replace(/&#39;/g, "'");
  text = text.replace(/\s+/g, ' ');
  text = text.replace(/\n{3,}/g, '\n\n');
  return text.trim();
}
