import React, { useEffect, useState, useCallback } from 'react';
import { IoClose } from 'react-icons/io5';

interface ToastProps {
  title: string;
  message?: string;
  type?: 'success' | 'error' | 'info';
  duration?: number;
  onClose?: () => void;
}

const Toast: React.FC<ToastProps> = ({ title, message, type = 'success', duration = 3000, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, handleClose]);

  if (!isVisible) return null;

  const bgColor = {
    success: 'bg-green-500',
    error: 'bg-red-500',
    info: 'bg-blue-500',
  }[type];

  return (
    <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-[60]">
      <div className={`${bgColor} text-white px-4 py-2 rounded-md shadow-lg transition-opacity duration-300 max-w-sm`}>
        <div className="flex justify-between items-start">
          <div>
            <h3 className="font-bold">{title}</h3>
            {message && <p className="text-sm mt-1">{message}</p>}
          </div>
          <button onClick={handleClose} className="ml-2 text-white hover:text-gray-200">
            <IoClose size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toast;
