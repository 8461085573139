import React from 'react';

interface LoadingSpinnerProps {
  message?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'inline';
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message = 'Loading...', size = 'medium', variant = 'default' }) => {
  const sizeClasses = {
    small: 'w-6 h-6',
    medium: 'w-12 h-12',
    large: 'w-16 h-16',
  };

  if (variant === 'inline') {
    return (
      <div className="flex items-center space-x-2">
        <div className="w-16 h-2 bg-cool-grey-02 rounded-full overflow-hidden relative">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-4 h-4 bg-cerulean rounded-full animate-ping opacity-75"></div>
          </div>
        </div>
        {message && <span className="text-cirrus text-sm">{message}</span>}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className={`${sizeClasses[size]} border-4 border-cool-grey-02 border-t-4 border-t-cerulean rounded-full animate-spin`}></div>
      {message && <p className="mt-4 text-cirrus">{message}</p>}
    </div>
  );
};

export default LoadingSpinner;