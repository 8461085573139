import { getEnvVariables } from '../utils/env';

export async function fetchAuthData(token: string) {
  const { API_URL } = getEnvVariables();
  const apiUrl = `${API_URL}/auth/user-data`;
  const response = await fetch(apiUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  
  return response.json();
}