import React, { useState, createContext, ReactNode } from 'react';
import Sidebar, { SidebarOption } from '../navigation/Sidebar';
import TitleSection from '../ui-elements/TitleSection';
import { IconType } from 'react-icons';
import { useDataContext } from '../../App';
import { PopupModal } from './PopupModal';
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

interface Breadcrumb {
  label: string;
  path?: string;
}

interface PageLayoutProps {
  children: React.ReactNode;
  showSidebar?: boolean;
  sidebarOptions?: SidebarOption[];
  sidebarTitle?: string;
  pageTitle?: string;
  pageSubtitle?: string;
  pageIcon?: IconType;
  pageAction?: ReactNode;
  onSidebarOptionClick?: (path: string) => void;
  contentClassName?: string;
  withContentWrapper?: boolean;
  breadcrumbs?: Breadcrumb[];
}

interface PopupContextType {
  openPopup: (content: ReactNode, title: string) => void;
  closePopup: () => void;
}

export const PopupContext = createContext<PopupContextType | undefined>(undefined);

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  showSidebar = false,
  sidebarOptions = [],
  sidebarTitle = 'Sections',
  pageTitle,
  pageSubtitle,
  pageIcon,
  pageAction,
  onSidebarOptionClick,
  contentClassName = '',
  withContentWrapper = true,
  breadcrumbs = [],
}) => {
  const { deviceType } = useDataContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [popupContent, setPopupContent] = useState<ReactNode | null>(null);
  const [popupTitle, setPopupTitle] = useState<string>('');

  const openPopup = (content: ReactNode, title: string) => {
    setPopupContent(content);
    setPopupTitle(title);
  };

  const closePopup = () => {
    setPopupContent(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const sidebarWidth = isSidebarOpen ? 'w-64' : 'w-16';

  const renderBreadcrumbs = () => {
    if (breadcrumbs.length === 0) return null;

    return (
      <nav aria-label="Breadcrumb" className="py-2 px-4 bg-cool-grey-01 rounded-lg">
        <ol className="flex items-center space-x-2">
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <li className="flex items-center">
                  <FaChevronRight className="flex-shrink-0 h-4 w-4 text-cool-grey-02 mx-2" />
                </li>
              )}
              <li className={`flex items-center ${index === breadcrumbs.length - 1 ? 'text-cerulean font-medium' : ''}`}>
                {crumb.path ? (
                  <Link
                    to={crumb.path}
                    className="text-storm hover:text-cerulean transition-colors duration-200 flex items-center"
                  >
                    {index === 0 && (
                      <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                      </svg>
                    )}
                    <span className="hover:underline">{crumb.label}</span>
                  </Link>
                ) : (
                  <span className="flex items-center">
                    {index === 0 && (
                      <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                      </svg>
                    )}
                    {crumb.label}
                  </span>
                )}
              </li>
            </React.Fragment>
          ))}
        </ol>
      </nav>
    );
  };

  return (
    <PopupContext.Provider value={{ openPopup, closePopup }}>
      <div className="fixed inset-0 top-16 bottom-16 p-2.5">
        <div className="flex h-full gap-2.5">
          {/* Sidebar */}
          {showSidebar && (
            <div className={`${sidebarWidth} transition-all duration-300 ease-in-out`}>
              <Sidebar
                options={sidebarOptions}
                title={sidebarTitle}
                isOpen={isSidebarOpen}
                onToggle={toggleSidebar}
                deviceType={deviceType}
              />
            </div>
          )}

          {/* Main content container */}
          <div className="flex-1 flex flex-col gap-2.5 min-w-0">
            {/* Title Section */}
            <div className={`flex-shrink-0 ${breadcrumbs.length > 0 ? 'space-y-2.5' : ''}`}>
              {pageTitle && (
                <TitleSection 
                  title={pageTitle} 
                  subtitle={pageSubtitle}
                  icon={pageIcon}
                  action={pageAction}
                />
              )}
              {/* Breadcrumbs */}
              {renderBreadcrumbs()}
            </div>

            {/* Content area */}
            <div className="flex-1 min-h-0 relative">
              {withContentWrapper ? (
                <div className="absolute inset-0 bg-white rounded-lg shadow-sm">
                  <div className="absolute inset-0 overflow-auto">
                    <div className="p-6">
                      {children}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={`absolute inset-0 ${contentClassName}`}>
                  <div className="absolute inset-0 overflow-auto">
                    {children}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Popup Modal */}
      {popupContent && (
        <PopupModal title={popupTitle} onClose={closePopup}>
          {popupContent}
        </PopupModal>
      )}
    </PopupContext.Provider>
  );
};

export default PageLayout;
