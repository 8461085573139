import React from 'react';
import { Paper, Typography, Box, Tooltip, IconButton } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { useDataContext } from '../../App';
import { HelpCircle } from 'lucide-react';
import { chartColors } from '../../utils/chartColors';

interface WeeklyTotalCount {
  week: string;
  open: number;
  closed: number;
}

interface TotalTicketTrendChartProps {
  weeklyTotalCounts: WeeklyTotalCount[];
  title: string;
}

const TotalTicketTrendChart: React.FC<TotalTicketTrendChartProps> = ({
  weeklyTotalCounts,
  title,
}) => {
  const { deviceType } = useDataContext();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const chartHeight = deviceType === 'mobile' ? 300 : deviceType === 'tablet' ? 350 : 400;

  const tooltipText = `
    This chart shows the flow of tickets over the last 3 months:
    • The dark area represents tickets that were created and have since been closed
    • The light area represents tickets that remain open
    • The total height shows the cumulative number of tickets created each week
    • The stacked view helps visualize the relationship between open and closed tickets over time
  `;

  return (
    <Paper 
      elevation={3} 
      className="p-6 rounded-lg shadow-md"
    >
      <Box className="flex items-center gap-2 mb-2">
        <Typography variant="h6" className="text-storm font-semibold">
          Cumulative Ticket Flow (Last 3 Months)
        </Typography>
        <Tooltip 
          title={tooltipText}
          arrow
          placement="top"
          classes={{
            tooltip: 'max-w-md whitespace-pre-line'
          }}
        >
          <IconButton size="small" className="text-cool-grey-01">
            <HelpCircle size={16} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <LineChart
          xAxis={[{ 
            scaleType: 'band', 
            data: weeklyTotalCounts.map(item => formatDate(item.week)),
            label: 'Week',
            tickLabelStyle: {
              angle: 0,
              textAnchor: 'middle',
              fontSize: 10,
            },
          }]}
          yAxis={[{
            tickMinStep: 1,
            label: 'Number of Tickets'
          }]}
          series={[
            {
              data: weeklyTotalCounts.map(item => item.closed),
              label: 'Closed Tickets',
              color: chartColors.status.closed,
              area: true,
              stack: 'total',
            },
            {
              data: weeklyTotalCounts.map(item => item.open),
              label: 'Open Tickets',
              color: chartColors.status.open,
              area: true,
              stack: 'total',
            },
          ]}
          height={chartHeight}
          margin={{
            left: 50,
            bottom: 50,
            right: 20,
            top: 20,
          }}
          slotProps={{
            legend: {
              hidden: false,
              position: {
                vertical: 'top',
                horizontal: 'right',
              },
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default TotalTicketTrendChart;
