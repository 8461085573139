import React from 'react';
import { TablePagination } from '@mui/material';
import LoadingSpinner from '../ui-elements/LoadingSpinner';
import ErrorMessage from '../ui-elements/ErrorMessage';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchCaseEmails } from '../../api/cases';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import AddCaseComment from './AddCaseComment';
import Toast from '../ui-elements/Toast';

interface EmailMessage {
  Id: string;
  Subject: string;
  FromAddress: string;
  ToAddress: string;
  MessageDate: string;
  TextBody: string;
  HTMLBody: string;
  Status: string;
}

interface EmailListProps {
  caseId: string;
  caseNumber: string;
  caseSubject: string;
  refreshTrigger?: number;
}

interface CollapsibleEmailProps {
  email: EmailMessage;
  formatDate: (date: string) => string;
}

const CollapsibleEmail: React.FC<CollapsibleEmailProps> = React.memo(({ email, formatDate }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const previewLength = 200;
  
  const getPreviewText = () => {
    const content = email.HTMLBody || email.TextBody || '';
    // Strip HTML tags for preview
    const strippedContent = content.replace(/<[^>]+>/g, '');
    return strippedContent.length > previewLength 
      ? `${strippedContent.substring(0, previewLength)}...`
      : strippedContent;
  };

  return (
    <div className="border rounded-lg p-4 hover:shadow-md transition-shadow bg-white">
      <div className="flex justify-between items-center mb-2">
        <span className="font-semibold text-lg text-cerulean">{email.Subject}</span>
        <span className="text-cool-grey-01 text-sm">{formatDate(email.MessageDate)}</span>
      </div>
      <div className="text-sm text-cool-grey-01 mb-3">
        <div className="flex items-center gap-2">
          <span className="font-medium">From:</span>
          <span>{email.FromAddress}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium">To:</span>
          <span>{email.ToAddress}</span>
        </div>
      </div>
      <div className="prose max-w-none mt-4 border-t pt-4">
        <div className="flex justify-between items-start">
          <div className="flex-1">
            {isExpanded ? (
              <div className="transition-all duration-300">
                {email.HTMLBody ? (
                  <div dangerouslySetInnerHTML={{ __html: email.HTMLBody }} />
                ) : (
                  <pre className="whitespace-pre-wrap text-gray-700">{email.TextBody}</pre>
                )}
              </div>
            ) : (
              <p className="text-gray-700">{getPreviewText()}</p>
            )}
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="flex items-center gap-2 text-cerulean hover:text-cerulean/80 ml-4 text-sm font-medium whitespace-nowrap"
          >
            {isExpanded ? (
              <>
                <FaChevronUp className="w-4 h-4" />
                Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="w-4 h-4" />
                Read More
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
});

export const EmailList: React.FC<EmailListProps> = ({ 
  caseId, 
  caseNumber, 
  caseSubject,
  refreshTrigger = 0 
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [emails, setEmails] = React.useState<EmailMessage[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [page, setPage] = React.useState(0);
  const [totalEmails, setTotalEmails] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [showCommentModal, setShowCommentModal] = React.useState(false);
  const [showSuccessToast, setShowSuccessToast] = React.useState(false);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  const refreshEmails = React.useCallback(async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const response = await fetchCaseEmails(token, caseId, page, pageSize);
      setEmails(response.records);
      setTotalEmails(response.totalSize);
    } catch (err) {
      console.error('Error fetching emails:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch emails');
    } finally {
      setLoading(false);
    }
  }, [caseId, page, pageSize, getAccessTokenSilently]);

  React.useEffect(() => {
    if (caseId) {
      refreshEmails();
    }
  }, [caseId, page, pageSize, getAccessTokenSilently, refreshEmails, refreshTrigger]);

  if (loading) return <LoadingSpinner message="Loading emails..." />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="space-y-4">
      {emails.length === 0 ? (
        <div className="text-center py-8 bg-white rounded-lg">
          <p className="text-gray-500">No emails found for this case.</p>
        </div>
      ) : (
        <div className="space-y-4 overflow-auto px-6 pt-6 rounded-lg bg-white">
          <div className="will-change-transform">
            {emails.map((email) => (
              <div key={email.Id} className="transform-gpu mb-4">
                <CollapsibleEmail
                  email={email}
                  formatDate={formatDate}
                />
              </div>
            ))}
          </div>
          <div className="sticky bottom-0 bg-white z-10 pt-4">
            <TablePagination
              component="div"
              count={totalEmails}
              page={page}
              onPageChange={(_, newPage) => setPage(newPage)}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value, 10))}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </div>
        </div>
      )}
      
      {/* Add Comment Modal */}
      {showCommentModal && (
        <AddCaseComment
          caseId={caseId}
          caseNumber={caseNumber}
          onCommentSent={(success) => {
            if (success) {
              setShowSuccessToast(true);
              refreshEmails();
              setShowCommentModal(false);
            }
          }}
          onClose={() => setShowCommentModal(false)}
        />
      )}

      {/* Add Toast */}
      {showSuccessToast && (
        <Toast
          title="Comment Added"
          message="Your comment has been successfully added to the case."
          type="success"
          onClose={() => setShowSuccessToast(false)}
        />
      )}
    </div>
  );
};

export default EmailList;
