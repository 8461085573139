import React from 'react';
import { EditorContent, useEditor, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import Typography from '@tiptap/extension-typography';
import { FaBold, FaItalic, FaStrikethrough, FaCode } from 'react-icons/fa';
import { RiParagraph, RiH1, RiH2 } from 'react-icons/ri';
import { MdFormatClear } from 'react-icons/md';

interface RichTextEditorProps {
  content: string;
  onUpdate: (content: string) => void;
  key?: string;
  disabled?: boolean;
}

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="flex flex-wrap items-center gap-2 border-b border-gray-300 p-3 bg-gray-100">
      <Button 
        onClick={() => editor.chain().focus().setParagraph().run()} 
        disabled={!editor.can().chain().focus().setParagraph().run()}
        isActive={editor.isActive('paragraph')} 
        icon={<RiParagraph size={20} />} 
        label="Paragraph" 
      />
      <Button 
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} 
        disabled={!editor.can().chain().focus().toggleHeading({ level: 1 }).run()}
        isActive={editor.isActive('heading', { level: 1 })} 
        icon={<RiH1 size={20} />} 
        label="Heading 1" 
      />
      <Button 
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} 
        disabled={!editor.can().chain().focus().toggleHeading({ level: 2 }).run()}
        isActive={editor.isActive('heading', { level: 2 })} 
        icon={<RiH2 size={20} />} 
        label="Heading 2" 
      />
      <Button 
        onClick={() => editor.chain().focus().toggleBold().run()} 
        disabled={!editor.can().chain().focus().toggleBold().run()}
        isActive={editor.isActive('bold')} 
        icon={<FaBold size={20} />} 
        label="Bold" 
      />
      <Button 
        onClick={() => editor.chain().focus().toggleItalic().run()} 
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        isActive={editor.isActive('italic')} 
        icon={<FaItalic size={20} />} 
        label="Italic" 
      />
      <Button 
        onClick={() => editor.chain().focus().toggleStrike().run()} 
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        isActive={editor.isActive('strike')} 
        icon={<FaStrikethrough size={20} />} 
        label="Strike" 
      />
      <Button 
        onClick={() => editor.chain().focus().toggleCode().run()} 
        disabled={!editor.can().chain().focus().toggleCode().run()}
        isActive={editor.isActive('code')} 
        icon={<FaCode size={20} />} 
        label="Code" 
      />
      <Button 
        onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
        disabled={!editor.can().chain().focus().unsetAllMarks().clearNodes().run()}
        icon={<MdFormatClear size={20} />} 
        label="Clear formatting" 
      />
    </div>
  );
};

const RichTextEditor: React.FC<RichTextEditorProps> = ({ content, onUpdate, key, disabled }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      TextStyle,
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      Typography,
    ],
    content,
    onUpdate: ({ editor }) => {
      onUpdate(editor.getHTML());
    },
    editorProps: {
      attributes: {
        class: 'prose max-w-none p-4 focus:outline-none min-h-[200px] border border-gray-300 rounded ProseMirror',
      },
    },
    editable: !disabled,
  });

  return (
    <div className={`border border-gray-300 rounded ${disabled ? 'opacity-50' : ''}`} key={key}>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
};

interface ButtonProps {
  onClick: () => void;
  disabled: boolean;
  isActive?: boolean;
  icon: React.ReactNode;
  label: string;
}

const Button: React.FC<ButtonProps> = ({ onClick, disabled, isActive, icon, label }) => (
  <button
    type="button"
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    disabled={disabled}
    className={`flex items-center justify-center px-3 py-2 border rounded text-lg ${
      isActive ? 'bg-blue-600 text-white' : 'bg-gray-800 text-white'
    } hover:bg-blue-600 hover:text-white transition-colors duration-200 ${
      disabled ? 'opacity-50 cursor-not-allowed' : ''
    }`}
    aria-label={label}
  >
    {icon}
  </button>
);

export default RichTextEditor;