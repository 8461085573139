import React from 'react';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

interface SortArrowProps {
  sortKey: string;
  currentSortKey: string;
  direction: 'ascending' | 'descending';
}

const SortArrow: React.FC<SortArrowProps> = ({ sortKey, currentSortKey, direction }) => {
  if (sortKey !== currentSortKey) {
    return <FaSort className="text-cool-grey-01" />;
  }

  return direction === 'ascending' ? (
    <FaSortUp className="text-storm" />
  ) : (
    <FaSortDown className="text-storm" />
  );
};

export default SortArrow;