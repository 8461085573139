import axios from 'axios';
import { getEnvVariables } from '../utils/env';
import { Case } from '../types/CaseRecord';

const { API_URL } = getEnvVariables();

interface CreateCaseData extends Partial<Case> {
  ContactId: string;
  AccountId: string;
  Subject: string;
  Description?: string;
  FromAddress: string;
  FromName: string;
}

interface EmailMessage {
  Id: string;
  Subject: string;
  FromAddress: string;
  ToAddress: string;
  MessageDate: string;
  TextBody: string;
  HTMLBody: string;
  Status: string;
}

interface EmailResponse {
  records: EmailMessage[];
  totalSize: number;
}

async function fetchCasesHelper(token: string, endpoint: string, params: Record<string, string | number | undefined>) {
  const response = await axios.get(`${API_URL}/cases/${endpoint}`, {
    headers: { Authorization: `Bearer ${token}` },
    params
  });
  return response.data;
}

export async function fetchCasesByContactId(token: string, contactId: string, offset?: number, pageSize?: number) {
  return fetchCasesHelper(token, `contact/${contactId}`, { offset, pageSize });
}

export async function fetchOpenCasesByContactId(token: string, contactId: string, offset?: number, pageSize?: number) {
  return fetchCasesHelper(token, `contact/${contactId}/open`, { offset, pageSize });
}

export async function fetchClosedCasesByContactId(token: string, contactId: string, offset?: number, pageSize?: number) {
  return fetchCasesHelper(token, `contact/${contactId}/closed`, { offset, pageSize });
}

export async function fetchCasesByAccountId(token: string, accountId: string, offset?: number, pageSize?: number) {
  return fetchCasesHelper(token, `account/${accountId}`, { offset, pageSize });
}

export async function fetchOpenCasesByAccountId(token: string, accountId: string, offset?: number, pageSize?: number) {
  return fetchCasesHelper(token, `account/${accountId}/open`, { offset, pageSize });
}

export async function fetchClosedCasesByAccountId(token: string, accountId: string, offset?: number, pageSize?: number) {
  return fetchCasesHelper(token, `account/${accountId}/closed`, { offset, pageSize });
}

export async function fetchCasesByJiraStatus(token: string, idType: 'contact' | 'account', id: string, jiraStatus: string, offset?: number, pageSize?: number) {
  return fetchCasesHelper(token, `${idType}/${id}/jira-status/${encodeURIComponent(jiraStatus)}`, { offset, pageSize });
}

export const addEmailToCase = async (token: string, caseId: string, emailData: any) => {
  const response = await axios.post(`${API_URL}/cases/${caseId}/add-comment`, emailData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const fetchCaseDetails = async (token: string, caseId: string): Promise<Case> => {
  const response = await axios.get(`${API_URL}/cases/${caseId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const createCase = async (token: string, caseData: CreateCaseData) => {
  const response = await axios.post(`${API_URL}/cases/salesforce`, caseData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const closeCase = async (token: string, caseId: string) => {
  const response = await axios.put(`${API_URL}/cases/${caseId}/close`, {}, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const openCase = async (token: string, caseId: string) => {
  const response = await axios.put(`${API_URL}/cases/${caseId}/open`, {}, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const createCaseWithEmail = async (token: string, caseData: CreateCaseData) => {
  const response = await axios.post(`${API_URL}/cases/create-with-comment`, caseData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const fetchCases = async (token: string, caseType: string, idType: string, id: string, jiraStatus: string | undefined, page: number, pageSize: number) => {
  try {
    let endpoint = '';
    if (idType === 'account') {
      endpoint = `account/${id}`;
    } else if (idType === 'contact') {
      endpoint = `contact/${id}`;
    }

    if (caseType === 'open') {
      endpoint += '/open';
    } else if (caseType === 'closed') {
      endpoint += '/closed';
    }

    if (jiraStatus) {
      endpoint += `/jira-status/${encodeURIComponent(jiraStatus)}`;
    }

    const params = new URLSearchParams({
      offset: ((page - 1) * pageSize).toString(),
      pageSize: pageSize.toString(),
    });

    const response = await axios.get(`${API_URL}/cases/${endpoint}?${params.toString()}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching cases:', error);
    throw error;
  }
};

export const fetchCaseEmails = async (
  token: string,
  caseId: string,
  page: number = 0,
  pageSize: number = 10
): Promise<EmailResponse> => {
  try {
    const response = await axios.get(
      `${API_URL}/cases/${caseId}/emails`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          take: pageSize,
          skip: page * pageSize,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching case emails:', error);
    throw error;
  }
};
