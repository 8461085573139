import React, { useState } from 'react';
import LoadingSpinner from '../components/ui-elements/LoadingSpinner';
import PageLayout from '../components/layout/PageLayout';
import { FaHome } from 'react-icons/fa';
import { Box, Tabs, Tab } from '@mui/material';
import { useDataContext } from '../App';
import useCases from '../hooks/useCases';
import MyAnalytics from '../components/analytics/MyAnalytics';
import AccountAnalytics from '../components/analytics/AccountAnalytics';

const Home: React.FC = () => {
  const { loading: contextLoading, contactRecord, accountRecord } = useDataContext();
  const [activeTab, setActiveTab] = useState(0);

  const { cases: accountCases, loading: casesLoading, error: casesError } = useCases('all', 'account', accountRecord?.Id || '', undefined, 1000);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const firstName = contactRecord?.FirstName || 'User';

  return (
    <PageLayout pageTitle={`Welcome, ${firstName}`} pageIcon={FaHome}>
      <div className="flex flex-col h-full">
        <Box 
          sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            backgroundColor: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 10,
          }}
        >
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="My Analytics" />
            <Tab label="Account Analytics" />
          </Tabs>
        </Box>
        
        <div className="flex-1 overflow-auto p-4">
          {contextLoading ? (
            <Box className="flex justify-center">
              <LoadingSpinner message="Loading user data..." />
            </Box>
          ) : casesLoading ? (
            <Box className="flex justify-center">
              <LoadingSpinner message="Loading cases..." />
            </Box>
          ) : casesError ? (
            <div className="text-red-500">Error loading cases: {casesError.message}</div>
          ) : (
            <div className="grid grid-cols-1 gap-4">
              {activeTab === 0 && (
                <MyAnalytics
                  contactRecord={contactRecord}
                  accountCases={accountCases}
                  showSummaryOnly={false}
                />
              )}
              {activeTab === 1 && (
                <AccountAnalytics
                  accountCases={accountCases}
                  showSummaryOnly={false}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default Home;
