import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import CaseDetails from '../components/case/CaseDetails';
import EmailList from '../components/case/EmailList';
import LoadingSpinner from '../components/ui-elements/LoadingSpinner';
import ErrorMessage from '../components/ui-elements/ErrorMessage';
import { fetchCaseDetails } from '../api/cases';
import { Case } from '../types/CaseRecord';
import PageLayout from '../components/layout/PageLayout';
import { FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { SidebarOption } from '../components/navigation/Sidebar';
import AddCaseComment from '../components/case/AddCaseComment';
import Toast from '../components/ui-elements/Toast';
import CaseActionsMenu from '../components/case/CaseActionsMenu';

const CaseDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [caseData, setCaseData] = useState<Case | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentSection, setCurrentSection] = useState(location.hash || '#details');
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [toastConfig, setToastConfig] = useState<{
    title: string;
    message: string;
    type: 'success' | 'error' | 'info';
  } | null>(null);
  const [closingCaseId, setClosingCaseId] = useState<string | null>(null);
  const [openingCaseId, setOpeningCaseId] = useState<string | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [emailListRefreshTrigger, setEmailListRefreshTrigger] = useState(0);

  useEffect(() => {
    if (!location.hash) {
      navigate(`/cases/${id}#details`, { replace: true });
      setCurrentSection('#details');
    } else {
      setCurrentSection(location.hash);
    }
  }, [location.hash, navigate, id]);

  useEffect(() => {
    const loadCaseDetails = async () => {
      if (!id) {
        setError('No case ID provided');
        setLoading(false);
        return;
      }
      
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const data = await fetchCaseDetails(token, id);
        setCaseData(data);
      } catch (err) {
        console.error('Error loading case details:', err);
        setError('Failed to load case details');
      } finally {
        setLoading(false);
      }
    };

    loadCaseDetails();
  }, [id, getAccessTokenSilently]);

  useEffect(() => {
    if (refreshTrigger > 0) {
      const loadCaseDetails = async () => {
        try {
          const token = await getAccessTokenSilently();
          const data = await fetchCaseDetails(token, id!);
          setCaseData(data);
        } catch (err) {
          console.error('Error reloading case details:', err);
        }
      };
      loadCaseDetails();
    }
  }, [refreshTrigger, getAccessTokenSilently, id]);

  const sidebarOptions: SidebarOption[] = [
    {
      label: 'Case Details',
      path: `#details`,
      icon: FaInfoCircle,
    },
    {
      label: 'Case Emails',
      path: `#emails`,
      icon: FaEnvelope,
    },
  ];

  const getBreadcrumbs = () => {
    const breadcrumbs = [
      { label: 'Tickets', path: '/tickets' },
    ];

    if (caseData) {
      breadcrumbs.push({ 
        label: `Case ${caseData.CaseNumber}`,
        path: undefined // This makes TypeScript happy and shows it's intentionally undefined
      });
    }

    return breadcrumbs;
  };

  const handleCommentSent = (success: boolean) => {
    if (success) {
      setToastConfig({
        title: "Comment Added",
        message: "Your comment has been successfully added to the case.",
        type: "success"
      });
      setShowCommentModal(false);
      setEmailListRefreshTrigger(prev => prev + 1);
    }
  };

  const renderContent = () => {
    if (error) return <ErrorMessage message={error} />;
    if (loading) return <LoadingSpinner message="Loading case details..." />;
    if (!caseData) return <ErrorMessage message="Case not found" />;

    return (
      <>
        {currentSection === '#details' && (
          <CaseDetails
            caseData={caseData}
            isClosing={false}
            isOpening={false}
          />
        )}
        
        {currentSection === '#emails' && id && (
          <EmailList 
            caseId={id}
            caseNumber={caseData.CaseNumber || ''}
            caseSubject={caseData.Subject || ''}
            refreshTrigger={emailListRefreshTrigger}
          />
        )}
      </>
    );
  };

  if (!id) return <ErrorMessage message="No case ID provided" />;

  const handleCaseClosed = (caseId: string, newStatus: string) => {
    setToastConfig({
      title: "Case Closed",
      message: `Case ${caseData?.CaseNumber} has been closed successfully.`,
      type: "success"
    });
    setRefreshTrigger(prev => prev + 1);
  };

  const handleCaseOpened = (caseId: string, newStatus: string) => {
    setToastConfig({
      title: "Case Reopened",
      message: `Case ${caseData?.CaseNumber} has been reopened successfully.`,
      type: "success"
    });
    setRefreshTrigger(prev => prev + 1);
  };

  const handleActionStart = (caseId: string, action: 'open' | 'close') => {
    if (action === 'open') {
      setOpeningCaseId(caseId);
    } else {
      setClosingCaseId(caseId);
    }
  };

  const handleActionEnd = () => {
    setOpeningCaseId(null);
    setClosingCaseId(null);
  };

  const pageActions = caseData ? (
    <CaseActionsMenu
      caseItem={caseData}
      onCaseClosed={handleCaseClosed}
      onCaseOpened={handleCaseOpened}
      onSendComment={() => setShowCommentModal(true)}
      closingCaseId={closingCaseId}
      openingCaseId={openingCaseId}
      onActionStart={handleActionStart}
      onActionEnd={handleActionEnd}
    />
  ) : null;

  return (
    <>
      <PageLayout
        showSidebar={true}
        sidebarOptions={sidebarOptions}
        sidebarTitle="Case Sections"
        pageTitle={`Case: ${caseData?.CaseNumber || 'Loading...'}`}
        pageSubtitle={caseData?.Subject || 'Loading...'}
        pageIcon={FaInfoCircle}
        pageAction={pageActions}
        withContentWrapper={false}
        breadcrumbs={getBreadcrumbs()}
      >
        <div className="w-full bg-white rounded-lg shadow-sm">
          {renderContent()}
        </div>
      </PageLayout>

      {/* Comment Modal */}
      {showCommentModal && (
        <AddCaseComment
          caseId={id || ''}
          caseNumber={caseData?.CaseNumber || ''}
          onCommentSent={handleCommentSent}
          onClose={() => setShowCommentModal(false)}
        />
      )}

      {/* Replace Success Toast with configurable Toast */}
      {toastConfig && (
        <Toast
          title={toastConfig.title}
          message={toastConfig.message}
          type={toastConfig.type}
          onClose={() => setToastConfig(null)}
        />
      )}
    </>
  );
};

export default CaseDetailsPage;
