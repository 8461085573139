import React from 'react';
import Logo from '../ui-elements/Logo';

const Footer: React.FC = () => {
  return (
    <footer className="bg-storm text-cirrus py-2 fixed bottom-0 left-0 right-0 h-16 z-40">
      <div className="container mx-auto px-4 flex justify-between items-center h-full">
        <Logo name="Ekwis-Horizontal-Cerulean" className="h-12 w-auto" />
        <p className="text-sm">
          © {new Date().getFullYear()} Ekwis. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
