import React from 'react';
import { Paper, Typography, Box, Tooltip, IconButton } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { useDataContext } from '../../App';
import { HelpCircle } from 'lucide-react';
import { getChartColorByIndex } from '../../utils/chartColors';

interface WeeklyTicketCount {
  week: string;
  [key: string]: number | string;
}

interface TicketTrendChartProps {
  weeklyTicketCounts: WeeklyTicketCount[];
  ticketTypes: string[];
  title: string;
}

const TicketTrendChart: React.FC<TicketTrendChartProps> = ({
  weeklyTicketCounts,
  ticketTypes,
  title,
}) => {
  const { deviceType } = useDataContext();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const chartHeight = deviceType === 'mobile' ? 300 : deviceType === 'tablet' ? 350 : 400;
  const legendWidth = deviceType === 'mobile' ? '100%' : deviceType === 'tablet' ? '30%' : 160;
  const chartWidth = deviceType === 'mobile' ? '100%' : deviceType === 'tablet' ? '70%' : `calc(100% - ${legendWidth}px)`;

  const tooltipText = `
    This chart breaks down tickets by category over the last 3 months:
    • Each line represents a different ticket category
    • The y-axis shows the number of tickets created
    • The x-axis shows weekly intervals
    • Use this to identify trends in specific types of tickets
    • Hover over data points to see exact values
  `;

  const getLineColor = (index: number) => getChartColorByIndex(index);

  return (
    <Paper 
      elevation={3} 
      className="p-6 rounded-lg shadow-md"
    >
      <Box className="flex items-center gap-2 mb-2">
        <Typography variant="h6" className="text-storm font-semibold">
          {title}
        </Typography>
        <Tooltip 
          title={tooltipText}
          arrow
          placement="top"
          classes={{
            tooltip: 'max-w-md whitespace-pre-line'
          }}
        >
          <IconButton size="small" className="text-cool-grey-01">
            <HelpCircle size={16} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" flexDirection={deviceType === 'mobile' ? 'column' : 'row'}>
        <Box 
          width={legendWidth} 
          mb={deviceType === 'mobile' ? 2 : 0} 
          pr={2}
          order={deviceType === 'mobile' ? 2 : 1}
        >
          {ticketTypes.map((type, index) => (
            <Box key={type} display="flex" alignItems="center" mb={1}>
              <Box
                width={10}
                height={10}
                bgcolor={getLineColor(index)}
                mr={1}
                flexShrink={0}
              />
              <Typography variant="body2" noWrap>{type}</Typography>
            </Box>
          ))}
        </Box>
        <Box 
          width={chartWidth}
          order={deviceType === 'mobile' ? 1 : 2}
        >
          <LineChart
            xAxis={[{ 
              scaleType: 'band', 
              data: weeklyTicketCounts.map(item => formatDate(item.week)),
              label: 'Week',
              tickLabelStyle: {
                angle: deviceType === 'mobile' ? 45 : 0,
                textAnchor: deviceType === 'mobile' ? 'start' : 'middle',
                fontSize: 10,
              },
            }]}
            yAxis={[{
              tickMinStep: 1,
              label: 'Number of Tickets'
            }]}
            series={ticketTypes.map((type, index) => ({
              data: weeklyTicketCounts.map(item => (item[type] as number) || 0),
              label: type,
              color: getLineColor(index),
            }))}
            height={chartHeight}
            margin={{
              left: 50,
              bottom: deviceType === 'mobile' ? 60 : 50,
              right: 20,
              top: 20,
            }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default TicketTrendChart;
