import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { Tooltip } from '@mui/material';

export interface SidebarOption {
  label: string;
  path: string;
  icon: IconType;
}

interface SidebarProps {
  options: SidebarOption[];
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  deviceType: 'mobile' | 'tablet' | 'desktop';
}

const Sidebar: React.FC<SidebarProps> = ({ 
  options, 
  title, 
  isOpen, 
  onToggle,
  deviceType
}) => {
  const location = useLocation();
  const [showText, setShowText] = React.useState(isOpen);

  React.useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => setShowText(true), 150);
      return () => clearTimeout(timer);
    } else {
      setShowText(false);
    }
  }, [isOpen]);

  const isSelected = (path: string) => {
    // If the path starts with #, compare with location.hash
    if (path.startsWith('#')) {
      return location.hash === path;
    }
    // For paths with parameters, check if the current path starts with the option path
    if (path.includes('/tickets/')) {
      return location.pathname.startsWith(path);
    }
    // Otherwise compare with full pathname
    return location.pathname === path;
  };

  const sidebarClasses = `
    bg-white shadow-md flex flex-col h-full rounded-lg
    ${isOpen ? 'w-64' : 'w-16'}
    transition-[width] duration-300 ease-in-out overflow-hidden
  `;

  return (
    <div className={sidebarClasses}>
      <div className="p-4 flex items-center justify-between border-b border-cool-grey-02 h-[60px]">
        <div className="flex-1 overflow-hidden">
          {isOpen && (
            <h2 className={`
              text-xl font-bold text-storm
              transition-opacity duration-150
              ${showText ? 'opacity-100' : 'opacity-0'}
            `}>
              {title}
            </h2>
          )}
        </div>
        <button
          onClick={onToggle}
          className={`
            p-2 rounded-full hover:bg-cool-grey-01 text-storm 
            transition-all duration-200 hover:shadow-md active:scale-95
            ${!isOpen ? 'mx-auto' : ''}
          `}
        >
          {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>
      <nav className="flex-grow py-2 relative">
        <ul className="space-y-1 px-2">
          {options.map((option) => {
            const selected = isSelected(option.path);
            const linkContent = (
              <Link
                to={option.path}
                className={`
                  block relative h-[40px] rounded-md
                  transition-colors duration-200
                  ${selected
                    ? 'bg-cerulean text-white hover:bg-cerulean/90'
                    : 'text-cool-grey-01 hover:bg-cool-grey-02 hover:text-storm'
                  }
                `}
              >
                <div className="absolute inset-0 flex items-center">
                  <div className={`
                    transition-all duration-300 ease-in-out flex items-center
                    ${!isOpen && selected ? 'scale-125' : 'scale-100'}
                    ${isOpen ? 'ml-3' : 'w-16 justify-center'}
                  `}>
                    <option.icon className="text-lg" />
                  </div>
                  {isOpen && (
                    <div className={`
                      ml-3 transition-opacity duration-150
                      ${showText ? 'opacity-100' : 'opacity-0'}
                    `}>
                      <span className="whitespace-nowrap text-sm font-medium">
                        {option.label}
                      </span>
                    </div>
                  )}
                </div>
                {!isOpen && selected && (
                  <span className="absolute -right-1 top-1/2 -translate-y-1/2 w-1 h-8 bg-cerulean rounded-l-full" />
                )}
              </Link>
            );

            return (
              <li key={option.path}>
                {!isOpen ? (
                  <Tooltip 
                    title={option.label} 
                    placement="right"
                    arrow
                  >
                    {linkContent}
                  </Tooltip>
                ) : (
                  linkContent
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
