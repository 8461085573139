import React from 'react';
import { User } from '@auth0/auth0-react';
import { useDataContext } from '../../App';
import LoadingSpinner from '../ui-elements/LoadingSpinner';
import ErrorMessage from '../ui-elements/ErrorMessage';
import RecordDetails, { FieldConfig } from '../ui-elements/RecordDetails';

interface Auth0DetailsProps {
  user: User | undefined;
}

const Auth0Details: React.FC<Auth0DetailsProps> = ({ user }) => {
  const { loading, error } = useDataContext();

  if (loading) {
    return <LoadingSpinner message="Loading Auth0 details..." />;
  }

  if (error) {
    return <ErrorMessage message={`Error: ${error}`} />;
  }

  if (!user) {
    return <ErrorMessage message="No user data available" />;
  }

  const getConnection = (user: User): string => {
    const identities = user.identities || [];
    if (identities.length > 0 && identities[0].connection) {
      return identities[0].connection;
    }
    return user.sub?.split('|')[0] || 'N/A';
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'N/A' : date.toLocaleString();
  };

  const fields: FieldConfig[] = [
    { 
      label: 'Name', 
      value: `${user.given_name ?? ''} ${user.family_name ?? ''}`.trim() || 'N/A'
    },
    { label: 'Email', value: user.email },
    { label: 'Login Provider', value: getConnection(user) },
    { label: 'Last Login', value: formatDate(user.updated_at ?? '') }
  ];

  return (
    <div className="overflow-x-auto">
      <RecordDetails fields={fields} />
    </div>
  );
};

export default Auth0Details;
