import React from 'react';
import RichTextEditor from './RichTextEditor';

interface RichTextEditorWithLabelProps {
  label: string;
  content: string;
  onUpdate: (content: string) => void;
  editorKey: string;
  disabled?: boolean;
  required?: boolean;
}

export function RichTextEditorWithLabel({
  label,
  content,
  onUpdate,
  editorKey,
  disabled = false,
  required = false,
}: RichTextEditorWithLabelProps) {
  return (
    <div>
      <label htmlFor="description" className="block text-sm font-medium text-cool-grey-01 mb-1">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <RichTextEditor 
        content={content} 
        onUpdate={onUpdate} 
        key={editorKey}
        disabled={disabled}
      />
    </div>
  );
}
