import React from 'react';
import ErrorMessage from './ErrorMessage';
import PageLayout from '../layout/PageLayout';

interface NoContactFoundProps {
  pageTitle: string;
}

const NoContactFound: React.FC<NoContactFoundProps> = ({ pageTitle }) => {
  return (
    <PageLayout pageTitle={pageTitle}>
      <ErrorMessage message="Please contact support@ekwis.com to set you up on this portal." />
    </PageLayout>
  );
};

export default NoContactFound;