import React from 'react';
import { getStatusBadgeClasses } from '../../utils/statusStyles';

interface StatusConfig {
  style: {
    backgroundColor: string;
    textColor: string;
  };
}

export interface FieldConfig {
  label: string;
  value: string | undefined;
  isStatus?: boolean;
  statusConfig?: StatusConfig;
  fullWidth?: boolean;
}

interface RecordDetailsProps {
  fields: FieldConfig[];
  columns?: 1 | 2;
}

const RecordDetails: React.FC<RecordDetailsProps> = ({ fields, columns = 2 }) => {
  return (
    <div className="bg-white shadow-sm rounded-lg">
      <div className={`grid ${columns === 1 ? 'grid-cols-1' : 'grid-cols-2'} gap-x-6`}>
        {fields.map((field, index) => (
          <div 
            key={index}
            className={`p-4 border-b border-cool-grey-02 ${
              field.fullWidth ? 'col-span-2' : ''
            }`}
          >
            <p className="font-semibold text-cool-grey-01 mb-2">{field.label}:</p>
            {field.isStatus ? (
              <span className={getStatusBadgeClasses(field.statusConfig?.style)}>
                {field.value || 'N/A'}
              </span>
            ) : (
              <p className="text-storm whitespace-pre-wrap">{field.value || 'N/A'}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecordDetails;
