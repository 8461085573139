import React from 'react';
import LoadingSpinner from '../ui-elements/LoadingSpinner';
import AddCaseComment from './AddCaseComment';
import Toast from '../ui-elements/Toast';
import { getCaseStatusStyle, getJiraStatusStyle } from '../../utils/statusStyles';
import RecordDetails, { FieldConfig } from '../ui-elements/RecordDetails';

interface CaseDetailsProps {
  caseData: {
    Id?: string;
    Subject?: string;
    Status?: string;
    CaseNumber?: string;
    CreatedDate?: string;
    Description?: string;
    Origin?: string;
    Type?: string;
    Reason?: string;
    JIRA_Ticket_Key__c?: string;
    JIRA_Status__c?: string;
  };
  isClosing: boolean;
  isOpening: boolean;
}

const CaseDetails: React.FC<CaseDetailsProps> = ({ caseData, isClosing, isOpening }) => {
  const [showCommentModal, setShowCommentModal] = React.useState(false);
  const [showSuccessToast, setShowSuccessToast] = React.useState(false);
  
  const formatDate = (dateString?: string) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  if (isClosing || isOpening) {
    return <LoadingSpinner message={isClosing ? "Closing case..." : "Opening case..."} />;
  }

  const fields: FieldConfig[] = [
    { 
      label: 'Status', 
      value: caseData.Status,
      isStatus: true,
      statusConfig: { style: getCaseStatusStyle(caseData.Status) }
    },
    { label: 'Created Date', value: formatDate(caseData.CreatedDate) },
    { label: 'Origin', value: caseData.Origin },
    { label: 'Type', value: caseData.Type },
    { label: 'Reason', value: caseData.Reason },
    { label: 'JIRA Ticket', value: caseData.JIRA_Ticket_Key__c },
    { 
      label: 'JIRA Status', 
      value: caseData.JIRA_Status__c,
      isStatus: true,
      statusConfig: { style: getJiraStatusStyle(caseData.JIRA_Status__c) }
    },
    { label: 'Subject', value: caseData.Subject },
    { 
      label: 'Description', 
      value: caseData.Description,
      fullWidth: true 
    }
  ];

  return (
    <div className="space-y-4">
      <RecordDetails fields={fields} />

      {/* Comment Modal */}
      {showCommentModal && (
        <AddCaseComment
          caseId={caseData.Id || ''}
          caseNumber={caseData.CaseNumber || ''}
          onCommentSent={(success) => {
            if (success) {
              setShowSuccessToast(true);
              setShowCommentModal(false);
            }
          }}
          onClose={() => setShowCommentModal(false)}
        />
      )}

      {/* Add Toast */}
      {showSuccessToast && (
        <Toast
          title="Comment Added"
          message="Your comment has been successfully added to the case."
          type="success"
          onClose={() => setShowSuccessToast(false)}
        />
      )}
    </div>
  );
};

export default CaseDetails;
