import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

interface LogoProps {
  name: string;
  className?: string;
}

interface LogoData {
  url: string;
  timestamp: number;
}

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const getCachedLogo = (name: string): LogoData | null => {
  const cachedData = localStorage.getItem(`logo_${name}`);
  if (cachedData) {
    const { url, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_DURATION) {
      return { url, timestamp };
    }
  }
  return null;
};

const setCachedLogo = (name: string, url: string) => {
  const logoData: LogoData = { url, timestamp: Date.now() };
  localStorage.setItem(`logo_${name}`, JSON.stringify(logoData));
};

const Logo: React.FC<LogoProps> = ({ name, className }) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(() => {
    const cachedLogo = getCachedLogo(name);
    return cachedLogo ? cachedLogo.url : null;
  });

  const fetchLogo = useCallback(async (name: string) => {
    try {
      const url = `${process.env.REACT_APP_STRAPI_URL}/api/logos?filters[name]=${name}&populate=*`;
      const response = await axios.get(url);
      if (response.data.data.length > 0) {
        const logoData = response.data.data[0];
        if (logoData.image && logoData.image.url) {
          const imageUrl = `${process.env.REACT_APP_STRAPI_URL}${logoData.image.url}`;
          setLogoUrl(imageUrl);
          setCachedLogo(name, imageUrl);
        } else {
          console.error('Image data is missing in the response');
        }
      } else {
        console.error('No logo data found');
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
    }
  }, []);

  useEffect(() => {
    if (!logoUrl) {
      fetchLogo(name);
    }
  }, [name, logoUrl, fetchLogo]);

  if (!logoUrl) {
    return null;
  }

  return <img src={logoUrl} alt={`${name} Logo`} className={className} />;
};

export default Logo;
