// Color palette using Tower Wing's extended color system

export const chartColors = {
  // Primary colors for main data points
  primary: [
    '#004BFF',    // cerulean
    '#33558C',    // steel-blue
    '#3B4C63',    // slate
    '#72A4FF',    // highlight-blue
    '#809FFF',    // cerulean-soft
  ],
  
  // Secondary colors for additional data points
  secondary: [
    '#2D3946',    // storm-light
    '#495463',    // cool-grey-03
    '#7D8793',    // ash
    '#BDC4CF',    // light-silver
    '#D0D4DB',    // cool-grey-04
  ],

  // Neutral colors for supporting elements
  neutral: [
    '#1E2833',    // storm
    '#68707C',    // cool-grey-01
    '#AFB7C6',    // cool-grey-02
  ],

  // Status colors
  status: {
    open: '#004BFF',     // cerulean
    closed: '#3B4C63',   // slate
  }
};

// Helper function to get colors based on index
export const getChartColorByIndex = (index: number): string => {
  const allColors = [...chartColors.primary, ...chartColors.secondary];
  return allColors[index % allColors.length];
};
