import { Case } from '../types/CaseRecord';

export const getStatusColor = (status: string | undefined) => {
  if (!status) return 'bg-cool-grey-02 text-storm';
  
  switch (status.toLowerCase()) {
    case 'open':
      return 'bg-cerulean text-white';
    case 'on hold':
      return 'bg-cool-grey-01 text-white';
    case 'reopened':
      return 'bg-storm text-white';
    case 'closed':
      return 'bg-cool-grey-02 text-storm';
    default:
      return 'bg-cool-grey-02 text-storm';
  }
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
};

export const sortCases = (cases: Case[], sortConfig: { key: keyof Case; direction: 'ascending' | 'descending' }) => {
  return [...cases].sort((a, b) => {
    const aValue = (a[sortConfig.key] as string | number) || '';
    const bValue = (b[sortConfig.key] as string | number) || '';

    if (aValue < bValue) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
};
