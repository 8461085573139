import React from 'react';
import PageLayout from '../components/layout/PageLayout';
import AuthButton from '../components/ui-elements/AuthButton';
import { FaSignInAlt } from 'react-icons/fa';

const UnauthenticatedUser: React.FC = () => {
  return (
    <PageLayout pageTitle="Welcome! Please Log In" pageIcon={FaSignInAlt}>
      <div className="flex flex-col items-center justify-center min-h-[60vh]">
        <FaSignInAlt className="text-4xl text-cool-grey-01 mb-4" />
        <h1 className="text-2xl font-bold text-storm mb-4">Welcome to Ekwis Support Portal</h1>
        <p className="text-cool-grey-01 mb-6 text-center">
          To access your support cases and account information, please log in using your credentials.
        </p>
        <AuthButton />
      </div>
    </PageLayout>
  );
};

export default UnauthenticatedUser;
