import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchCases, fetchCasesByJiraStatus } from '../api/cases';
import { Case } from '../types/CaseRecord';

const useCases = (caseType: 'all' | 'open' | 'closed' | 'to-test' | 'jira-status', idType: 'contact' | 'account', id: string, jiraStatus: string | undefined, initialPageSize: number) => {
  const [cases, setCases] = useState<Case[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [totalCases, setTotalCases] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const { getAccessTokenSilently } = useAuth0();

  const fetchCasesData = useCallback(async () => {
    if (!id) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      let response;

      if (caseType === 'jira-status' && jiraStatus) {
        response = await fetchCasesByJiraStatus(token, idType, id, jiraStatus, (page - 1) * pageSize, pageSize);
      } else {
        response = await fetchCases(token, caseType, idType, id, jiraStatus, page, pageSize);
      }

      setCases(response.records);
      setTotalCases(response.totalSize);
    } catch (error) {
      setError(error instanceof Error ? error : new Error('An unknown error occurred'));
      console.error('Error fetching cases:', error);
    } finally {
      setLoading(false);
    }
  }, [caseType, idType, id, jiraStatus, page, pageSize, getAccessTokenSilently]);

  useEffect(() => {
    fetchCasesData();
  }, [fetchCasesData]);

  const setCustomPageSize = useCallback((newSize: number) => {
    setPageSize(newSize);
    setPage(1);
  }, []);

  const refetch = useCallback(() => {
    fetchCasesData();
  }, [fetchCasesData]);

  return { cases, loading, error, totalCases, page, setPage, pageSize, setCustomPageSize, refetch };
};

export default useCases;
