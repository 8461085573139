import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';

const AuthButton: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <button className="bg-cool-grey-02 text-storm px-4 py-2 rounded-md font-medium cursor-not-allowed" disabled>
        Loading...
      </button>
    );
  }

  const buttonClasses = "bg-cerulean hover:bg-cool-grey-01 text-cirrus hover:text-cerulean px-5 py-3 rounded-md text-base font-medium transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center";

  if (isAuthenticated) {
    return (
      <button
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        className={buttonClasses}
      >
        <FaSignOutAlt className="mr-2" /> Log Out
      </button>
    );
  } else {
    return (
      <button
        onClick={() => loginWithRedirect()}
        className={buttonClasses}
      >
        <FaSignInAlt className="mr-2" /> Log In
      </button>
    );
  }
};

export default AuthButton;